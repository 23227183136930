import {generateRecaptchaToken} from '../helpers/recaptcha';

'use strict';

document.addEventListener("DOMContentLoaded", function () {

    $(function () {
        $('[data-toggle="tooltip"]').tooltip();
    });

    function hidePreloader(timeoutFast, timeoutLong) {
        setTimeout(function () {
            if (!$(".parallax").length) {
                if ($("iframe").length) {
                    setTimeout(function () {
                        $(".preload__wrapper").fadeOut();
                    }, timeoutFast + 100)
                } else {
                    $(".preload__wrapper").fadeOut();
                }

            } else {
                setTimeout(function () {
                    $(".preload__wrapper").fadeOut();
                }, timeoutLong)
            }
        }, timeoutFast)
    }

    hidePreloader(500, 1000);

    grecaptcha.ready(function () {
        generateRecaptchaToken();
    });

    //FUNCTION ON ORIENTATION CHANGE
    $(window).on("orientationchange", function () {
        var vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', vh + "px");
    });
    //FUNCTIONS ON RESIZE
    $(window).on("resize", function (e) {
        var vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', vh + "px");
    });

    //FUNCTIONS ON RESIZE END

    //ANCHOR SMOOTH SCROLLING
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.addEventListener('click', function (e) {
            e.preventDefault();

            const currentAnchor = this.getAttribute('href');
            const currentBlock = document.querySelector(currentAnchor);

            currentBlock.scrollIntoView({
                behavior: 'smooth'
            });
        });
    });

    //ANCHOR SMOOTH SCROLLING END
//------------------------------------------------------------//

    //DYNAMIC OG:IMAGE

    //DYNAMIC OG:IMAGE END
//------------------------------------------------------------//

//PREVENTING EXTERNAL LINKS
    $("[data-href]").on("click", function () {
        var link = $(this).data("href");

        window.open(
            link,
            '_blank'
        );

    });
//PREVENTING EXTERNAL LINKS

    hidePreloader(100, 300);

    //AJAX PORTFOLIO DESIGN


});

// ADD DYNAMICALLY TABINDEX
$(document).ready(function () {
    var i = 20;
    $(".page-content__wrapper a").each(function () {
        $(this).attr("tabindex", i++);
    });
});
// ADD DYNAMICALLY TABINDEX

// ADD READ MORE BUTTON IN SECTION
$(".success-history").on("click", "#button-read-more", function (e) {
    e.preventDefault;
    $(this)
        .toggleClass("show")
        .siblings("#text-more").slideToggle("slow");

    if ($(this).hasClass("show")) {
        $(this).html("Read Less");
    } else {
        $(this).html("Read More");
    }
});
// ADD READ MORE BUTTON IN SECTION

// ADD CLASS IN ACTIVE TAG
$('.blog-tags__item a').each(function () {
    const tagHref = $(this).attr('href');
    const trimTagSlug = tagHref.split('?')[1];

    if (window.location.href.includes(trimTagSlug)) {
        $(this).closest('li').addClass('active-tag');
    }
});
